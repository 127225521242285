import { PageProps } from "gatsby";
import React from "react";

import { Chords } from "../../components/pages/Chords";

import { dnProps } from "../../js/utils";

const ChordsPage = (props: PageProps) => (
  <Chords {...{ ...dnProps, ...props }} />
);

export default ChordsPage;
